<template>

  <el-tooltip
    v-if="$canAndRulesPass(scope.row, $permissions.LIBRARY.ELEMENT_UPDATE)"
    content="Редактировать"
    placement="top"
    :open-delay="500"
  >
    <el-button
      class="table-action-button"
      type="default"
      round
      size="mini"
      icon="fas fa-pen"
      @click="$emit('edit-element', scope.row)"
    >
    </el-button>
  </el-tooltip>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
export default {
  name: 'element-actions-table-column',
  components: {ClickToEdit},

  props: {
    scope: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {},
  methods: {
    deleteElement(element) {
      this.loading = true;
      this.$api.card.deleteElement({
        id: element.id,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('elements-changed');
        })
    },
  }
}

</script>

<style>

</style>
